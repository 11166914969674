import $ from 'jquery';

const initBirthdayDate = () => {
    $('#profile_birthdate').on('change', function () {
        var birthdate = $(this).val();
        if (birthdate) {
            birthdate = new Date(birthdate);
            var today = new Date();
            var age = Math.floor((today - birthdate) / (365.25 * 24 * 60 * 60 * 1000));
            $("label[for='profile_birthdate']").html(`Data de nascimento (${age} Anos)`);
        } else {
            $("label[for='profile_birthdate']").html('');
        }
    });
};

export default initBirthdayDate;