// POSSIBLY NOT NEEDED. Looks like the modal submit issue is 
// due to poorly formatted html


// This is mostly to help Modals figure out how to submit the form consitently

// If a form is having trouble submitting from inside a modal
// Add this to the html `onclick="formSubmit('form_id_htere')"`
// Usually that means adding this to the simple form `onclick: "formSubmit('form_id_here')"`
const initFormSubmit = () => {
  window.formSubmit = (formId) => {
    const elm = document.getElementById(formId);
    elm.submit();
  }
}

export default initFormSubmit;
