import $ from 'jquery';

const initCopyButton = () => {
    $('.phone-field').after('<div class="input-group-append phone-field"><button class="bg-teal-600 text-white px-6 hover:no-underline hover:text-white hover:bg-teal-700" id="copy-phone">Copiar<button></div>');
    $('.phone-field').wrapAll('<div style="display: flex"></div>');
    $("#copy-phone").on("click", function (event) {
        event.preventDefault();
        $("#professional_personal_info_attributes_telephone").val($("#user_phone").val())
    });
};

export default initCopyButton;
