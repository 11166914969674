import $ from 'jquery';


const initpopImagePreview = () => {

    const popImages = document.querySelectorAll(".pop");
    
    if (popImages){
        popImages.forEach(element => element.addEventListener("click", function(event){
            event.preventDefault();
            document.getElementById("imagepreview").src = event.target.src;
            $("#imagemodal").modal('show');
        })
        );
    }


}

export default initpopImagePreview;