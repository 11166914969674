import $ from 'jquery';

const initJobForm = () => {
  const perfisDeLook = document.querySelectorAll('.perfil-de-look')
  if (perfisDeLook){
    const dados = {
      'A': {
          value_four: '300,00',
          value_twelve: '550,00'
        },
      'B': {
        value_four: '250,00',
        value_twelve: '350,00'
      },
      'C': {
        value_four: '200,00',
        value_twelve: '300,00'
      }
    };

    perfisDeLook.forEach(elem => {
      const first_part = `
      <div class="perfil ml-4">
        <div class="ml-8">
        `

      const second_part = `</div>
        <div class="valores">
          <div class="valor-hora flex w-full">
            <p class="font-bold text-tiny"><span class="text-teal-400 text-xl font-bold mr-5">|</span>R$ ${dados[elem.firstElementChild.value]['value_four']}</p>
            <p class="text-gray-600 ml-2">(4h)</p>
          </div>
          <div class="valor-hora flex w-full">
            <p class="font-bold text-tiny"><span class="text-teal-400 text-xl font-bold mr-5">|</span>R$ ${dados[elem.firstElementChild.value]['value_twelve']}</p>
            <p class="text-gray-600 ml-2">(12h)</p>
          </div>
        </div>
      </div>`

      elem.innerHTML = first_part + elem.innerHTML + second_part
    })
  }

  const addFieldButton = document.querySelector('.add_fields');
  if (addFieldButton){
    addFieldButton.addEventListener("click", (event) => {
      const time = new Date().getTime()
      const regexp = new RegExp($(addFieldButton).data('id'), 'g')
      $(addFieldButton).before($(addFieldButton).data('fields').replace(regexp, time))
      event.preventDefault();
    })
  }

  document.addEventListener('click', (event) => {
    let elem = event.target.closest('.remove_fields')
    if (elem){
      $(elem.previousElementSibling).find('input').val('1')
      $(elem).closest('fieldset').hide();
    }
    let editarBotaoAtivo = event.target.closest('.botao-2')
    if (editarBotaoAtivo){
      event.preventDefault();
      $(event.target.parentElement.parentElement).hide()
      $(event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild.firstElementChild.lastElementChild).addClass('botao-editar-1')
      $(event.target.parentElement.parentElement.previousElementSibling.firstElementChild.firstElementChild.firstElementChild.lastElementChild).removeClass('botao-editar-2')
    }
  })

  const editarPerfis = document.querySelectorAll('.botao-editar-1')
  if (editarPerfis){
    editarPerfis.forEach(elem => {
      elem.addEventListener('click', (event) => {
        event.preventDefault();
        $(event.target).addClass('botao-editar-2')
        $(event.target).removeClass('botao-editar-1')
        $(event.target.parentElement.parentElement.parentElement.parentElement.nextElementSibling).show()
      })
    })
  }
}

export default initJobForm;
