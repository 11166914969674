import $ from 'jquery';

const initShowContactInfoModalToProfessional = () => {
  const showContactInfoModal = () => {
    $('#professionalContactInfoModal').modal('show');
    var closeButton = modal.querySelector('[data-dismiss="modal"]');
    closeButton.addEventListener('click', function() {
      $('#professionalContactInfoModal').modal('hide');
    });
  }

  const showModal = document.querySelector('#showModal')
  if (!showModal) return;

  const showModalBool = showModal.getAttribute('value');
  if (showModalBool === 'true') {
    showContactInfoModal();
  }
}

export default initShowContactInfoModalToProfessional;
