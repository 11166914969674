import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';

const loadCropper = (parent) => {
  const img = parent.querySelector('.jcropper-container img');
  const cropper = new Cropper(img, {
    aspectRatio: 3 / 4,
    zoomable: false,
    checkCrossOrigin: false,
    minCropBoxWidth: 100,
    ready() {
      const rotateElm = parent.querySelector('.jcropper-tools .jcropper-rotate');
      const unrotateElm = parent.querySelector('.jcropper-tools .jcropper-unrotate');

      rotateElm.addEventListener('click', () => this.cropper.rotate(90));
      unrotateElm.addEventListener('click', () => this.cropper.rotate(-90));
    },
    crop(event) {
      parent.querySelector('.jcropper-inputs .jcropper-x').value = event.detail.x;
      parent.querySelector('.jcropper-inputs .jcropper-y').value = event.detail.y;
      parent.querySelector('.jcropper-inputs .jcropper-width').value = event.detail.width;
      parent.querySelector('.jcropper-inputs .jcropper-height').value = event.detail.height;
      parent.querySelector('.jcropper-inputs .jcropper-rotate').value = event.detail.rotate;
      parent.querySelector('.jcropper-inputs .jcropper-scaleX').value = event.detail.scaleX;
      parent.querySelector('.jcropper-inputs .jcropper-scaleY').value = event.detail.scaleY;
    },
  });
}

const buildInputName = (baseAttributeName, attributeName) => {
  return `${baseAttributeName.slice(0, -1)}_transform[${attributeName}]`
}

const buildCropperContainerHtml = (imageSrc, attributeName, hasDataSrc = false) => {
  return `
  <div class="jcropper-container">
    <div>
      <img src="${imageSrc}" class="" />
    </div>
    <div class="jcropper-tools">
      <div>
        <div class="jcropper-tool jcropper-rotate">
          <i class="fas fa-redo" aria-hidden="true"></i>
        </div>
        <div class="jcropper-tool jcropper-unrotate">
          <i class="fas fa-undo" aria-hidden="true"></i>
        </div>
      </div>
      <div></div>
      <div>
        ${hasDataSrc ? '<div class="jcropper-tool jcropper-stop"><i class="fas fa-times" aria-hidden="true"></i></div>' : ''}
      </div>
      </div>
    <div class="jcropper-inputs">
      <input type="hidden" class="jcropper-x" name="${buildInputName(attributeName, "x")}">
      <input type="hidden" class="jcropper-y" name="${buildInputName(attributeName, "y")}">
      <input type="hidden" class="jcropper-width" name="${buildInputName(attributeName, "width")}">
      <input type="hidden" class="jcropper-height" name="${buildInputName(attributeName, "height")}">
      <input type="hidden" class="jcropper-rotate" name="${buildInputName(attributeName, "rotate")}">
      <input type="hidden" class="jcropper-scaleX" name="${buildInputName(attributeName, "scaleX")}">
      <input type="hidden" class="jcropper-scaleY" name="${buildInputName(attributeName, "scaleY")}">
    </div>
  </div>
  `;
}

const buildPreviewContainerHtml = (imageSrc) => {
  return `
  <div class="jcropper-container">
    <div>
      <img src="${imageSrc}" class="" />
    </div>
    <div class="jcropper-tools">
      <div></div>
      <div></div>
      <div class="jcropper-tool jcropper-edit">
        <i class="fas fa-edit" aria-hidden="true"></i>
      </div>
    </div>
  </div>
  `;
}

const loadPreviewContainer = (jcropperElm, imageSrc) => {
  clearContainer(jcropperElm);
  const attributeName = jcropperElm.name;
  const parent = jcropperElm.parentElement;

  const previewHtml = buildPreviewContainerHtml(imageSrc, attributeName);
  jcropperElm.parentElement.insertAdjacentHTML('beforeEnd', previewHtml);
  jcropperElm.parentElement.querySelectorAll('.jcropper-tools .jcropper-edit').forEach(function(elem){
    elem.addEventListener('click', (evt) => {
      loadCropperContainer(jcropperElm, imageSrc);
    });
  });
}

const loadCropperContainer = (jcropperElm, imageSrc) => {
  clearContainer(jcropperElm);
  const attributeName = jcropperElm.name;
  const parent = jcropperElm.parentElement;
  
  const containerHtml = buildCropperContainerHtml(imageSrc, attributeName, !!jcropperElm.dataset.imgSrc);
  jcropperElm.parentElement.insertAdjacentHTML('beforeEnd', containerHtml);
  loadCropper(jcropperElm.parentElement);
  
  const stopElm = parent.querySelector('.jcropper-tools .jcropper-stop');
  if (stopElm) stopElm.addEventListener('click', () => loadPreviewContainer(jcropperElm, imageSrc))
}

const clearContainer = (jcropperElm) => {
  const preview = jcropperElm.parentElement.querySelector('.jcropper-container');
  if (preview) preview.remove();
}

const initJcropper = () => {
  document.querySelectorAll('input.jcropper').forEach((elm) => {
    if (elm.dataset.imgSrc) {
      loadPreviewContainer(elm, elm.dataset.imgSrc);
    }

    elm.addEventListener("change", (evt) => {
      const jcropperElm = evt.target;
      const attributeName = jcropperElm.name

      const file = jcropperElm.files[0];
      if (file) {
        const reader = new FileReader();  
        reader.addEventListener("load", function(evt) {
          const imageSrc = evt.target.result
          loadCropperContainer(this.jcropperElm, imageSrc);
        }.bind({ jcropperElm }));
        reader.readAsDataURL(file);
      }
    });
  });
};

export default initJcropper;