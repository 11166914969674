import $ from 'jquery';

const initFunctionChoice = () => {
    $('.function-choice').on("click",function(){
        $(this).toggleClass("checkbox-active");
    });
};

export default initFunctionChoice;

