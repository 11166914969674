const initiSidebarToggle = () => {
  const collapseElm = document.querySelector('#sidebarCollapse')
  if (collapseElm) {
    document.querySelector('#sidebarCollapse').addEventListener("click", (evt) => {
      document.querySelector(".layout-sidebar").classList.toggle('active');
      document.querySelector(".layout-content").classList.toggle('full');
    });
  }
}

export default initiSidebarToggle;