import $ from 'jquery';

const initSelectRequestApplications = () => {
  const selecionarPerfis = document.querySelectorAll('.ver-mais')
  if (selecionarPerfis){
    selecionarPerfis.forEach(elem => {
      elem.addEventListener('click', (event) => {
        event.preventDefault();
        $(event.target).toggleClass('botao-editar-2')
        $(event.target.parentElement.parentElement.nextElementSibling).toggle()
      })
    })
  }
}

export default initSelectRequestApplications;
