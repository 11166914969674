document.addEventListener('turbolinks:load', () => {
  const mapElement = document.getElementById('map');
  if (mapElement) {
    const latitude = parseFloat(mapElement.dataset.latitude);
    const longitude = parseFloat(mapElement.dataset.longitude);

    const mapOptions = {
      center: { lat: latitude, lng: longitude },
      zoom: 15,
    };

    const map = new google.maps.Map(mapElement, mapOptions);

    new google.maps.Circle({
      strokeColor: "#006E76",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#006E76",
      fillOpacity: 0.35,
      map: map,
      center: { lat: latitude, lng: longitude },
      radius: 500,
    });
  }
});
