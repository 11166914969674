import $ from 'jquery';
import 'select2';
import '../../../node_modules/select2/dist/css/select2.min.css'

const options = {
    width: '100%'
}


const initSelect2 = () => {
    $('.select2').select2(options);
};

export default initSelect2;