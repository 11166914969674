import _ from 'lodash';

function initAutocomplete() {
  let placeSearch = ["address"];
  let autocomplete;
  const establishmentType = document.getElementById("establishment_type");

  const autocompleteField = document.getElementById("autocomplete");

  if (autocompleteField){

    if (establishmentType != null){
      document.getElementById("establishment_type").addEventListener("change", function(){
        autocomplete.setTypes([document.getElementById('establishment_type').value]);
      });
    }

    const action = _.debounce(() => {
      autocomplete = new google.maps.places.Autocomplete(
        autocompleteField,
        {
          types: placeSearch,
          componentRestrictions: { "country": ["BR"] },
          fields: ["address_component", "geometry", "name"]
        }
      );

        const fillInAddress = () => {
          const place = autocomplete.getPlace();

          if (!place.geometry){
            document.getElementById('autocomplete').placeholder = "Coloque um endereço válido";
          } else {
            document.getElementById('autocomplete').innerHTML = place.name;
          }

          const componentForm = {
          street_number: "short_name",
          route: "long_name",
          sublocality_level_1: "long_name",
          administrative_area_level_2: "long_name",
          administrative_area_level_1: "long_name",
          postal_code: "short_name",
          };

          for (const component in componentForm) {
            if (document.getElementById(component) != null ){
              document.getElementById(component).value = "";
            }
          }

          for (const component of place.address_components) {
              const addressType = component.types[0];

              if (componentForm[addressType]) {
                const val = component[componentForm[addressType]];
                if (document.getElementById(addressType)){
                  document.getElementById(addressType).value = val;
                }
              }
          }
        };

        autocomplete.addListener("place_changed", fillInAddress);
    }, 2000)

    autocompleteField.addEventListener("input", action)
  }

};

function geolocate() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      const geolocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
      const circle = new google.maps.Circle({
        center: geolocation,
        radius: position.coords.accuracy,
      });
      autocomplete.setBounds(circle.getBounds());
    });
  }
}

export {initAutocomplete};
