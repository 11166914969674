import $ from 'jquery';
import "../../../node_modules/jquery-mask-plugin/src/jquery.mask";


const initJqueryMask = () => {
  var SPMaskBehavior = function (val) {
      return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
  },
    spOptions = {
      onKeyPress: function (val, e, field, options) {
        field.mask(SPMaskBehavior.apply({}, arguments), options);
      }
    };

  $('#user_phone').mask(SPMaskBehavior, spOptions);
  $('#professional_personal_info_attributes_telephone').mask(SPMaskBehavior, spOptions);

  $('#professional_personal_info_attributes_cpf').on("keypress", function(){
    $(this).mask('000.000.000-00');
  });

  $('.profile_user_attributes_tax_id').mask('000.000.000-00');

  $('#cnpj').mask('00.000.000/0000-00');

  const toggleCnpjInput = () => {
    if ($('#professional_personal_info_attributes_has_cnpj').val() === "no_cnpj") {
      $('#cnpj_group').hide();
    } else {
      $('#cnpj_group').show();
    }
  }
  toggleCnpjInput();
  $('#professional_personal_info_attributes_has_cnpj').on("change", toggleCnpjInput);

}

export default initJqueryMask;
