// http://ionden.com/a/plugins/ion.rangeSlider/index.html
// https://github.com/IonDen/ion.rangeSlider

import $ from 'jquery';
import 'ion-rangeslider';
import 'ion-rangeslider/css/ion.rangeSlider.min.css';
import '../css/init_ion_range_slider.css';

const initIonRangeSlider = () => {
  // Default options
  const options = {
    type: 'single',
    keyboard: true,
    skin: "round",
    hide_min_max: true
  }
  // Override default options using the html dataset properties
  const alternativeOptions = {
    type: 'double',
    keyboard: true,
    skin: "round",
    hide_min_max: true
  }

  $('.js-range-slider').ionRangeSlider(options);
  $('.double-range-slider').ionRangeSlider(alternativeOptions);

  // Adding special binding for shirt_size because the persistence isnt working with the normal data-values html prop
  const camisetaElm = document.querySelector('.js-range-slider-camiseta');
  if (camisetaElm) {
    $(camisetaElm).ionRangeSlider({
      prefix: 'Camiseta',
      values: camisetaElm.dataset.sizes.split(","),
      type: 'single',
      keyboard: true,
      skin: "round",
      hide_min_max: true
    })
  }
};

// config_from_data = {
//   skin: $inp.data("skin"),
//   type: $inp.data("type"),

//   min: $inp.data("min"),
//   max: $inp.data("max"),
//   from: $inp.data("from"),
//   to: $inp.data("to"),
//   step: $inp.data("step"),

//   min_interval: $inp.data("minInterval"),
//   max_interval: $inp.data("maxInterval"),
//   drag_interval: $inp.data("dragInterval"),

//   values: $inp.data("values"),

//   from_fixed: $inp.data("fromFixed"),
//   from_min: $inp.data("fromMin"),
//   from_max: $inp.data("fromMax"),
//   from_shadow: $inp.data("fromShadow"),

//   to_fixed: $inp.data("toFixed"),
//   to_min: $inp.data("toMin"),
//   to_max: $inp.data("toMax"),
//   to_shadow: $inp.data("toShadow"),

//   prettify_enabled: $inp.data("prettifyEnabled"),
//   prettify_separator: $inp.data("prettifySeparator"),

//   force_edges: $inp.data("forceEdges"),

//   keyboard: $inp.data("keyboard"),

//   grid: $inp.data("grid"),
//   grid_margin: $inp.data("gridMargin"),
//   grid_num: $inp.data("gridNum"),
//   grid_snap: $inp.data("gridSnap"),

//   hide_min_max: $inp.data("hideMinMax"),
//   hide_from_to: $inp.data("hideFromTo"),

//   prefix: $inp.data("prefix"),
//   postfix: $inp.data("postfix"),
//   max_postfix: $inp.data("maxPostfix"),
//   decorate_both: $inp.data("decorateBoth"),
//   values_separator: $inp.data("valuesSeparator"),

//   input_values_separator: $inp.data("inputValuesSeparator"),

//   disable: $inp.data("disable"),
//   block: $inp.data("block"),

//   extra_classes: $inp.data("extraClasses"),
// };

export { initIonRangeSlider };
